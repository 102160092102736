import { GridProps } from "@/types/components/grid/grid";

const Container = ({ full = false, size = "default", className = '', children }: GridProps) => {
    return (
        <div className={`px-3 maxMd:px-4 ${full ? 'w-full' : '2xl:w-1600 xl:w-1280 lg:w-1024 md:w-768 w-full mx-auto' } ${className}`}>
            {children}
        </div>
    )
}

export default Container;